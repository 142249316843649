import React from 'react';


import PageTemplate from '../templates/pageTemplate';
import { ArticleModel } from '../servermodels/articleModel';
import { PageBlockRichText } from '../servermodels/pageBlockModels';

const contentBlock: PageBlockRichText = {
    strapi_component: 'page-blocks.rich-text',
    Content: 'Ahoj'
}

const pageContext: ArticleModel = {
    Title: 'Moje preview',
    URL: '/preview',
    MetaKeywords: 'Key',
    MetaDescription: 'desc',
    PageBlock: [
        contentBlock
    ],
};

const PreviewPage: React.FC = () => {

    return (
        <PageTemplate pageContext={pageContext} />
    );
}

export default PreviewPage;